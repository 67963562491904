import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import './index.scss';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from 'react-redux'

import { useClicksUpdateMutation } from '../../services/action';

import Button from '@mui/material/Button';
function Clicks() {
  const history = useHistory()
  const location = useLocation();

  const { campId, userId } = useParams();
  const lintoUrl = new URLSearchParams(location.search).get('linkTo')

  const [updatedata, updateresult] = useClicksUpdateMutation()


  useEffect(() => {
    updatedata({
      campId: campId,
      userId: userId
    }) 
    window.location.replace('https://www.getthwifty.com');
  }, []);

 
  return (
    <>
    </>
  );
}

export default Clicks;
